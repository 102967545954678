<template>
  <div :class="!isDesktop ? 'md:px-2 lg:px-9' : 'md:px-9'">
    <div class="relative pl-2 lg:pl-0.5 ml-2 md:max-w-1400 md:mx-auto">
      <div class="flex flex-col items-start overflow-hidden w-full">
        <div class="relative">
          <div class="flex items-center gap-x-3">
            <img
              src="@/assets/svg/icons/icons-flower.svg"
              alt="Icono de flor"
              class="ml-6 w-6 h-6"
            />
            <span
              class="text-xl lg:text-2xl font-semibold font-bodoni tracking-wider"
            >
              {{ blok.title }}
            </span>
          </div>
          <div class="w-1 h-16 bg-[#3E5391] absolute top-1 left-2 z-10"></div>
        </div>

        <div
          @click="handleChangeLeft"
          v-if="isDesktop && leftArrow"
          class="h-10 w-10 grid place-items-center absolute top-0 bottom-0 -left-10 my-auto rounded-tr-md rounded-br-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer z-50"
        >
          <img
            src="@/assets/svg/icons/black/icons-left-arrow-light.svg"
            class="h-8"
          />
        </div>
        <div class="w-full mt-4 pb-7 md:pb-9 relative">
          <div
            ref="sliderContainer"
            :class="isDesktop ? '' : 'overflow-x-scroll scrollbar-hidden'"
            class="flex flex-nowrap space-x-2.5 mx-auto h-full py-0 pl-0 pr-4 translate-x-0 transition-all duration-500 w-full snap-x snap-mandatory -mb-8 md:-mb-5"
            :style="{ transform: `translateX(${translateValue}px)` }"
          >
            <component
              :is="item.component"
              :blok="item"
              v-for="(item, index) in blok.items"
              :key="index"
              class="rounded md:ml-1 min-h-[250px]"
            />
          </div>
        </div>
        <div
          @click="handleChangeRight"
          v-if="isDesktop && rightArrow"
          class="h-10 w-10 grid place-items-center absolute top-0 bottom-0 -right-10 my-auto rounded-tl-md rounded-bl-md bg-white bg-opacity-40 text-neutral-black-1 cursor-pointer z-50"
        >
          <img
            src="@/assets/svg/icons/black/icons-right-arrow-light.svg"
            class="h-8"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
})

const { isDesktop } = useDevice()
const sliderContainer = ref(null)
const leftArrow = ref(false)
const rightArrow = ref(true)
const translateValue = ref(0)

onMounted(() => {
  const itemCount = props.blok.items.length
  if (isDesktop && itemCount > 3) {
    rightArrow.value = true
  } else {
    rightArrow.value = false
  }

  if (isDesktop) {
    checkArrows()
  }
})

const checkArrows = () => {
  const container = sliderContainer.value
  const containerWidth = container.clientWidth
  const maxScrollWidth = container.scrollWidth - containerWidth

  leftArrow.value = translateValue.value < 0
  rightArrow.value = translateValue.value > -maxScrollWidth
}

const handleChangeRight = () => {
  const container = sliderContainer.value
  const containerWidth = container.clientWidth
  const moveAmount = containerWidth * 0.96
  const maxScrollWidth = container.scrollWidth - containerWidth

  if (translateValue.value > -maxScrollWidth) {
    translateValue.value = Math.max(
      translateValue.value - moveAmount,
      -maxScrollWidth
    )
    checkArrows()
  }
}

const handleChangeLeft = () => {
  const containerWidth = sliderContainer.value.clientWidth
  const moveAmount = containerWidth * 0.96

  if (translateValue.value < 0) {
    translateValue.value = Math.min(translateValue.value + moveAmount, 0)
    checkArrows()
  }
}
</script>
